
    import ContactCard from "@/components/contact-card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            ContactCard,
        },
    })

    // *****************************************************************************************************************
    export default class DistrictTermsAndConditions extends Vue {
        // Data function
        public data() {
            return {
                termsAndConditionsPathAndName:
                    require("@/assets/images/icon_book.png"),
            };
        }
    }
